
/*
const mainStyles = StyleSheet.create({
  wholeView: {
    flex: 1
  },  
  centeredView: {
      flex: 1, 
      justifyContent: "center", 
      alignItems: "center"
  },
  horizontalView: {
    flexDirection: "row",
    justifyContent: "space-between"
  },
  horizontalCenteredView: {
    alignItems: "center"
  },  
  horizontalEvenView: {
    justifyContent: "space-evenly"
  },
  messageView: {
    paddingHorizontal: 20,
    paddingVertical: 8
  },
  bottomAbsoluteView: {
    flex: 1,
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: "center",
  },
  overlayView: {
    flex: 1,
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,    
    opacity: 0.6,
    alignItems: "center",
    justifyContent: "center"
  },
  modalView: {
    margin: 20,
    backgroundColor: colors.lightGrey,
    padding: 30,
    paddingTop: 45,
    alignItems: "center",
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    elevation: 5
  },  
  borderBox: {

  },


})

*/

.heading {
  font-size: 16px;
  font-weight: bold;
  padding: 6px;
  margin-top: 12px;
  margin-left: 12px;
  color: map-get($colors, "darkGrey");
  display: inline-block;
}

.baseMarginView {
  margin: 20px 0;
}

.basePaddingView {
  padding: 20px;
}
.overlayView {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;   
  opacity: 0.6;
  text-align: center;

  .spinner-border {
    position: relative;
    width: 4rem;
    height: 4rem;
    top: calc( 50% - ( 4rem / 2) );
  }
}
.overlayView {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;   
  opacity: 0.6;
  text-align: center;

  .spinner-border {
    position: relative;
    width: 4rem;
    height: 4rem;
    top: calc( 50% - ( 4rem / 2) );
  }
}


.darkBackground {
  background-color: map-get($colors, "darkestGrey");
}

.lightBackground {
  background-color: map-get($colors, "lightGrey");
}

.middleBackground {
  background-color: map-get($colors, "darkGrey");
}

.baseBackground {
  background-color: map-get($colors, "white");
}

.wizard_header {
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: bold;
}

.wizard_text {
  text-align: justify;
  margin-bottom: 12px;
  font-size: 18px;
}

.boldText {
  font-weight: bold;
}

.baseText {
  color: map-get($colors, "black");
  font-size: 14;
}

.btn_base {
  //padding-left: 20px;
  //padding-right: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
  color: map-get($colors, "white");
}

.btn_label {
  font-size: 16px; 
  font-weight: bold; 
  text-transform: uppercase;
  color: map-get($colors, "white");
}

.input {
  color: map-get($colors, "black");
  background-color: map-get($colors, "white");
  font-size: 18px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0;
  height: 48px;
}
.input_label {
  color: map-get($colors, "darkGrey");
  display: inline-block;
  font-size: 16px;
  margin-left: 5px;
  margin-top: 5px;
}
.bottomScrollContainer {
  position: fixed;
  bottom: 8px;
  right: 8px;
  width: 140px;

  button {
    width: 100%;
    text-align: left;
    .btn_label {
      font-size: 18px;
    }
  }
}
/*
.input_addition {
  position: "absolute",
  padding: 11,
  right: 0,
  top: 0
}
.input_focused {
  borderBottomWidth: 3,
  borderRightWidth: 3,
  borderBottomColor: colors.middleGrey,
  borderRightColor: colors.middleGrey
}
.input_invalid: {
  borderTopColor: colors.darkRed,
  borderLeftColor: colors.darkRed,
  borderRightColor: colors.darkRed,
  borderBottomColor: colors.darkRed,
  borderWidth: 1
}
*/