
/*
const itemStyles = StyleSheet.create({

  jidelnicek_date_dark: {
    backgroundColor: colors.darkestRed
  },

  jidelnicek_heading_dark: {
    color: colors.lightGrey
  },

  jidelnicek_heading_view_dark: {
    borderBottomColor: colors.middleGrey,
  },

  jidelnicek_inner_dark: {
    backgroundColor: colors.darkGrey
  },
})

export default itemStyles
*/

.rating {

  .MuiSvgIcon-root {
    font-size: 48px;
    color: map-get($colors, "yellow");
  }
}

.rating-show {

  .MuiSvgIcon-root {
    color: map-get($colors, "yellow");
  }
  float:right;
}

.bigger-card .custom-control {
  font-size: 20px;
  margin: 8px 0;
  padding-left: 1.5em;
  .custom-control-label::before, .custom-control-label::after {
    width: 1em;
    height: 1em;
    top: 0.25em;
    left: -1.5em;
  }  
  &.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    background-size: auto;
  }
}

.odebrano_wrapper {
  position: relative;

  .MuiSvgIcon-root {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    border-left: 1px solid map-get($colors, "lightGrey");
  }
  div {
    margin-right: 32px;
  }
}

.odebrano_ok {
  color: map-get($colors, "green");
}

.odebrano_nok {
  color: map-get($colors, "darkRed");
}

.jidelnicek_soup {
  color: #6c757d;
}
.jidelnicek_heading {
  font-weight: bold;
  color: map-get($colors, "middleGrey");
  font-size: 13px;
}

.jidelnicek_heading_view {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: map-get($colors, "lightGrey");
  margin-top: 12px;
  margin-bottom: 12px;
}

.jidelnicek_top_bar {
  background-color: map-get($colors, "lightGrey");
  z-index: 1019;
}

.jidelnicek_inner {
  padding: 20px 10px;
}

.jidelnicek_inner_light {
  background-color: map-get($colors, "white");
}

.jidelnicek_date {
  background-color: map-get($colors, "darkRed");
  color: map-get($colors, "white");
}
.jidelnicek_outer {
  margin-bottom: 20px;
}
.list_item {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom-width: 1px;
  background-color: map-get($colors, "white");
  border-bottom-color: map-get($colors, "lightGrey");
}

.list_label {
  font-size: 14px;
  color: map-get($colors, "black");
}
.list_label_big {
  font-size: 16px;
  font-weight: bold;
}
